import { Block } from 'payload/types';
import { Type as VideoType, Video } from '../Video';
import { Type as ImageType, Image } from '../Image';
import { Type as LinkType, Link } from '../Links/Link';
import { RichTextContent } from '../RichText';

export type TextBoxPosition = 'None' | 'Top Left' | 'Top Right' | 'Bottom Left' | 'Bottom Right';
export type Type = {
  blockType: 'stage';
  blockName?: string;
  heading?: RichTextContent;
  subheading?: RichTextContent;
  media: VideoType[] | ImageType[];
  primaryButtonLink?: LinkType[];
  secondaryButtonLink?: LinkType[];
  textBoxPosition: TextBoxPosition;
  topShader: boolean;
  bottomShader: boolean;
  theme: 'light' | 'dark';
};

export const Stage: Block = {
  slug: 'stage',
  labels: {
    singular: 'Stage',
    plural: 'Stages',
  },
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      type: 'richText',
      localized: true,
    },
    {
      label: 'Subheading',
      name: 'subheading',
      type: 'richText',
      localized: true,
      admin: {
        condition: (_, siblingData) => !!siblingData?.heading,
      },
    },
    {
      name: 'textBoxPosition',
      label: 'Textbox position',
      type: 'radio',
      options: [
        {
          label: 'Top Left',
          value: 'Top Left',
        },
        {
          label: 'Top Right',
          value: 'Top Right',
        },
        {
          label: 'Bottom Left',
          value: 'Bottom Left',
        },
        {
          label: 'Bottom Right',
          value: 'Bottom Right',
        },
      ],
      defaultValue: 'None',
      admin: {
        layout: 'horizontal',
        condition: (_, siblingData) => !!siblingData?.heading,
      },
    },
    {
      name: 'media',
      label: 'Image or Video',
      type: 'blocks',
      minRows: 0,
      maxRows: 1,
      blocks: [Video, Image],
      required: true,
    },
    {
      label: 'Primary button link',
      name: 'primaryButtonLink',
      type: 'blocks',
      minRows: 0,
      maxRows: 1,
      blocks: [Link],
    },
    {
      label: 'Secondary button link',
      name: 'secondaryButtonLink',
      type: 'blocks',
      minRows: 0,
      maxRows: 1,
      blocks: [Link],
      admin: {
        condition: (_, siblingData) => !!siblingData?.primaryButtonLink,
      },
    },
    {
      name: 'topShader',
      label: 'Top shader',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'bottomShader',
      label: 'Bottom shader',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
        description:
          'Theme of the image (Light provides grey text color, Dark provides white color).',
      },
    },
  ],
};
