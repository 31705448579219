import { RichText, Type as RichTextType } from './RichText';
import { Image, Type as ImageType } from './Image';
import { InnovationTeaser, Type as InnovationTeaserType } from './InnovationTeaser';
import { Header, Type as HeaderType } from './Header';
import { Footer, Type as FooterType } from './Footer';
import { Video, Type as VideoType } from './Video';
import { Stage, Type as StageType } from './Stage';
import { EditorialTeaser, Type as EditorialTeaserType } from './EditorialTeaser';
import { VerticalSpacer, Type as VerticalSpacerType } from './VerticalSpacer';
import { Anchor, Type as AnchorType } from './Anchor';
import { ReusableContent, Type as ReusableContentType } from './ReusableContent';
import { Accordion, Type as AccordionType } from './Accordion';

export type LayoutType =
  | RichTextType
  | ImageType
  | InnovationTeaserType
  | HeaderType
  | FooterType
  | VideoType
  | StageType
  | EditorialTeaserType
  | VerticalSpacerType
  | AnchorType
  | ReusableContentType
  | AccordionType;

export const blocks = [
  RichText,
  Image,
  InnovationTeaser,
  Header,
  Footer,
  Video,
  Stage,
  EditorialTeaser,
  VerticalSpacer,
  Anchor,
  ReusableContent,
  Accordion,
];
