import { CollectionConfig } from 'payload/types';
import TenantField from '../../admin/TenantField';
import { TenantAccessControl } from '../../admin/TenantAccessControl';
import TenantFilteringList from '../../admin/TenantFilteringList';

export type MediaType = {
  filename: string;
  width: number;
  height: number;
  alt: string;
  sizes: {
    xs?: {
      filename: string;
      width: number;
      height: number;
    };
    s?: {
      filename: string;
      width: number;
      height: number;
    };
    m?: {
      filename: string;
      width: number;
      height: number;
    };
    l?: {
      filename: string;
      width: number;
      height: number;
    };
    xl?: {
      filename: string;
      width: number;
      height: number;
    };
  };
  tenant: string;
};

const Media: CollectionConfig = {
  slug: 'media',
  admin: {
    components: {
      views: {
        List: TenantFilteringList,
      },
    },
  },
  access: new TenantAccessControl(),
  upload: {
    adminThumbnail: 'xs',
    formatOptions: { format: 'webp' },
    imageSizes: [
      {
        name: 'xs',
        width: 640,
        height: 360,
        formatOptions: { format: 'webp' },
      },
      {
        name: 's',
        width: 854,
        height: 480,
        formatOptions: { format: 'webp' },
      },
      {
        name: 'm',
        width: 1280,
        height: 720,
        formatOptions: { format: 'webp' },
      },
      {
        name: 'l',
        width: 1920,
        height: 1080,
        formatOptions: { format: 'webp' },
      },
      {
        name: 'xl',
        width: 2560,
        height: 1440,
        formatOptions: { format: 'webp' },
      },
      {
        name: 'jpg',
        formatOptions: { format: 'jpeg' },
      },
    ],
  },
  fields: [
    {
      name: 'alt',
      label: 'Alt Text',
      type: 'text',
      required: true,
    },
    {
      name: 'tenant',
      label: 'Tenant',
      type: 'text',
      required: true,
      admin: {
        position: 'sidebar',
        components: {
          Field: TenantField,
        },
      },
    },
  ],
};

export default Media;
