import { Block } from 'payload/types';
import { MediaType } from '../../../collections/Media/Media';
import { RichTextContent } from '../RichText';

export type Type = {
  blockType: 'innovationTeaser';
  blockName?: string;
  image?: MediaType;
  heading?: RichTextContent;
  subHeading?: RichTextContent;
  text?: RichTextContent;
  mediaPosition: 'left' | 'right';
  theme: 'light' | 'dark';
};

export const InnovationTeaser: Block = {
  slug: 'innovationTeaser',
  labels: {
    singular: 'Innovation Teaser',
    plural: 'Innovation Teaser',
  },
  fields: [
    {
      name: 'image',
      label: 'Image',
      type: 'upload',
      filterOptions: {
        mimeType: { contains: 'image' },
      },
      relationTo: 'media',
      required: true,
    },
    {
      name: 'heading',
      label: 'Heading',
      type: 'richText',
      localized: true,
    },
    {
      name: 'subHeading',
      label: 'Sub-heading',
      type: 'richText',
      localized: true,
    },
    {
      name: 'text',
      label: 'Text',
      type: 'richText',
      localized: true,
    },
    // TODO: buttons
    {
      name: 'mediaPosition',
      label: 'Image Position',
      type: 'radio',
      options: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ],
      defaultValue: 'left',
      admin: {
        layout: 'horizontal',
      },
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
      },
    },
  ],
};
