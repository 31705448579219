import { Block } from 'payload/types';

/**
 * Sizes are added automatically after adding a new value to the Enum.
 */
enum SPACING_SIZES {
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
  XXXL = 'xxxl',
}

/**
 * Colors are added automatically after adding a new value to the Enum.
 */
enum BACKGROUND_COLORS {
  White = 'white',
  Black = 'black',
  Red = 'red',
  Grey = 'grey',
  None = 'none',
}

export type Type = {
  blockType: 'verticalSpacer';
  blockName: string;
  spacing: SPACING_SIZES;
  backgroundColor?: BACKGROUND_COLORS;
};

export const VerticalSpacer: Block = {
  slug: 'verticalSpacer',
  labels: {
    singular: 'Vertical Spacer',
    plural: 'Vertical Spacers',
  },
  fields: [
    {
      name: 'spacing',
      type: 'select',
      admin: {
        isClearable: false,
        isSortable: false,
        description:
          'Spacing token to use. More here: https://react.ui.audi/?path=/docs/brand-identity-layout-system--page#spacing-matrix',
      },
      options: (Object.keys(SPACING_SIZES) as Array<keyof typeof SPACING_SIZES>).map((key) => ({
        label: key,
        value: SPACING_SIZES[key],
      })),
      required: true,
    },
    {
      name: 'backgroundColor',
      type: 'select',
      admin: {
        isClearable: false,
        isSortable: false,
      },
      options: (Object.keys(BACKGROUND_COLORS) as Array<keyof typeof BACKGROUND_COLORS>).map(
        (key) => ({
          label: key,
          value: BACKGROUND_COLORS[key],
        }),
      ),
      defaultValue: BACKGROUND_COLORS.None,
    },
  ],
};
