import { Block } from 'payload/types';
import { MediaType } from '../../../collections/Media/Media';

export type Type = {
  blockType: 'video';
  blockName?: string;
  landscape?: MediaType;
  portrait?: MediaType;
  landscapePlaceholder?: MediaType;
  portraitPlaceholder?: MediaType;
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  fullscreen?: boolean;
  theme: 'light' | 'dark';
};

export const Video: Block = {
  slug: 'video',
  labels: {
    singular: 'Video',
    plural: 'Videos',
  },
  fields: [
    {
      name: 'portrait',
      label: 'Video (Portrait view)',
      type: 'upload',
      relationTo: 'media',
      filterOptions: {
        mimeType: { contains: 'video' },
      },
      required: true,
      localized: true,
    },
    {
      name: 'landscape',
      label: 'Video (Landscape view)',
      type: 'upload',
      relationTo: 'media',
      filterOptions: {
        mimeType: { contains: 'video' },
      },
      required: true,
      localized: true,
    },
    {
      name: 'portraitPlaceholder',
      label: 'Placeholder for Portrait view',
      type: 'upload',
      filterOptions: {
        mimeType: { contains: 'image' },
      },
      relationTo: 'media',
      required: true,
    },
    {
      name: 'landscapePlaceholder',
      label: 'Placeholder for Landscape view',
      type: 'upload',
      filterOptions: {
        mimeType: { contains: 'image' },
      },
      relationTo: 'media',
      required: true,
    },
    {
      name: 'autoplay',
      label: 'Autoplay',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'loop',
      label: 'Play in loop',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'muted',
      label: 'Mute video',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'controls',
      label: 'Provide (browser default) controls',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'fullscreen',
      label: 'Allow fullscreen',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
      },
    },
  ],
};
