import { Block } from 'payload/types';

export type Type = {
  blockType: 'selfService';
  blockName?: string;
};

export const SelfService: Block = {
  slug: 'selfService',
  labels: {
    singular: 'MSI Self Service',
    plural: 'MSI Self Services',
  },
  fields: [],
};
