import { CollectionConfig } from 'payload/types';
import { blocks as commonBlocks, LayoutType as CommonLayoutType } from '../../blocks/common';
import { blocks as msiBlocks, LayoutType as MSILayoutType } from '../../blocks/msi';
import { blocks as nftBlocks, LayoutType as NFTLayoutType } from '../../blocks/nft';
import { Type as ImageType } from '../../blocks/common/Image';
import TenantField from '../../admin/TenantField';
import { TenantAccessControl } from '../../admin/TenantAccessControl';
import TenantFilteringList from '../../admin/TenantFilteringList';
import { setUndefinedSlugToEmpty, combineSlugAndTenant } from './Validation';
import { handleDuplicate } from './Hooks';

export type Layout = CommonLayoutType | MSILayoutType | NFTLayoutType;

export type PageType = {
  id: string | number;
  title: string;
  slug: string;
  tenant: string;
  slugPerTenant: string;
  isProtected?: boolean;
  layout: Layout[];
  meta: {
    title?: string;
    description?: string;
    image?: ImageType;
  };
};

export const Pages: CollectionConfig = {
  slug: 'pages',
  admin: {
    useAsTitle: 'title',
    components: {
      views: {
        List: TenantFilteringList,
      },
    },
    hooks: {
      beforeDuplicate: handleDuplicate,
    },
  },
  access: new TenantAccessControl(),
  fields: [
    {
      name: 'title',
      label: 'Page Title',
      type: 'text',
      required: true,
    },
    {
      label: 'Protect page',
      name: 'isProtected',
      type: 'checkbox',
      defaultValue: false,
      admin: {
        description:
          'Require the user to authenticate with the configured identity provider (e.g. MSI) before accessing the page.',
      },
    },
    {
      name: 'slug',
      label: 'Page Slug',
      type: 'text',
      validate: (val) => {
        if (val?.startsWith('/')) {
          return 'Please provide a slug without leading "/"';
        }

        if (!/^[A-Za-z0-9\-\/]*$/.test(val)) {
          return 'Slug has to be alphanumeric, additionally only "-" and "/" characters can be included';
        }

        return true;
      },
      admin: {
        description:
          'Provide alphanumeric slug, "-" and "/" are allowed. Leading "/" should be omitted.',
      },
    },
    {
      name: 'tenant',
      label: 'Tenant',
      type: 'text',
      required: true,
      admin: {
        position: 'sidebar',
        components: {
          Field: TenantField,
        },
      },
    },
    {
      name: 'slugPerTenant',
      label: 'Slug and Tenant',
      type: 'text',
      required: true,
      // Will just be used for validating that a slug is unique per tenant
      admin: { disabled: true },
      unique: true,
    },
    {
      name: 'layout',
      label: 'Page Layout',
      type: 'blocks',
      minRows: 0,
      blocks: [...commonBlocks, ...msiBlocks, ...nftBlocks],
    },
  ],
  hooks: {
    beforeValidate: [combineSlugAndTenant, setUndefinedSlugToEmpty],
  },
};

export default Pages;
