import { Block } from 'payload/types';

export type Type = {
  blockType: 'anchor';
  blockName?: string;
  targetName: string;
};

export const Anchor: Block = {
  slug: 'anchor',
  labels: {
    singular: 'Anchor',
    plural: 'Anchors',
  },
  fields: [
    {
      label: 'Anchor Name',
      name: 'targetName',
      type: 'text',
      required: true,
      validate: (val) => {
        if (!val || val === '') {
          return 'Required field';
        }

        if (/\s/g.test(val)) {
          return 'Please provide a name without Whitespaces or Tabs';
        }

        if (!/^[A-Za-z0-9\-_]*$/.test(val)) {
          return 'Anchor name has to be alphanumeric, additionally only "-" and "_" characters can be included';
        }

        return true;
      },
      admin: {
        description: 'Provide alphanumeric anchor name, "-" and "_" are allowed.',
      },
    },
  ],
};
