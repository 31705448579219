import * as env from './payload.environment';
import * as tuEnv from './techuser.environment';
import { buildConfig } from 'payload/config';
import { webpackBundler } from '@payloadcms/bundler-webpack';
import { mongooseAdapter } from '@payloadcms/db-mongodb';
import { slateEditor } from '@payloadcms/richtext-slate';
import path from 'path';
import Users from './collections/Users/Users';
import Pages from './collections/Pages/Pages';
import ReusableContent from './collections/ReusableContent/ReusableContent';
import Media from './collections/Media/Media';
import { seedPages } from './seed/seed';
import { cloudStorage } from '@payloadcms/plugin-cloud-storage';
import { s3Adapter } from '@payloadcms/plugin-cloud-storage/s3';
// Be careful to import LoginButton from dist/components to not bundle the auth0strategy
import LoginButton from 'payload-auth0-plugin/dist/components/LoginButton';
import { payloadRoutes } from './auth.environment';
import TenantSwitcher from './admin/TenantSwitcher';
import { createTechUser } from './seed/createTechUser';
import seo from '@payloadcms/plugin-seo';

const plugins = [];

plugins.push(
  seo({
    collections: ['pages'],
    tabbedUI: true,
    uploadsCollection: 'media',
    // Currently without localization (use argument "locale")
    generateImage: ({ doc }) => {
      const itemWithImage = Object.keys(doc).find(
        (key) => key.endsWith('.image') && doc[key].value,
      );
      return doc[itemWithImage]?.value;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    generateTitle: ({ doc }) => doc.title?.value,
  }),
);

plugins.push(
  cloudStorage({
    enabled: !env.DISABLE_S3_CLOUD_STORAGE_PLUGIN,
    collections: {
      [Media.slug]: {
        // Local storage does not make sense in a container,
        // so we only store the asset in the cloud
        disableLocalStorage: true,
        // Our assets are publicly available, so we do not want to proxy
        // the access via the CMS, but directly point to the S3 bucket
        disablePayloadAccessControl: true,
        // The "folder" in S3. Make sure to use a trailing /
        prefix: 'media/',
        adapter: s3Adapter({
          bucket: env.ASSETS_BUCKET_NAME,
          config: {
            // Credentials do not need to be provided here:
            // They are passed via env vars by AWS during runtime
            // Our container needs to have the correct permissions in its IAM role
          },
        }),
        // If possible, provide a cloudfront cached URL
        generateFileURL: ({ prefix, filename }) => {
          if (env.CDN_DOMAIN) {
            return `https://${env.CDN_DOMAIN}/${prefix}${filename}`;
          }
          return `https://${env.ASSETS_BUCKET_NAME}.s3.eu-central-1.amazonaws.com/${prefix}${filename}`;
        },
      },
    },
  }),
);

// Access control is server-only, it doesn't need to be bundled
const tenantAccessControl = path.resolve(__dirname, 'admin/TenantAccessControl');
const tenantAccessControlMock = path.resolve(__dirname, 'admin/__mocks__/TenantAccessControl');

// Hooks are server-only
const pageValidationHook = path.resolve(__dirname, 'collections/Pages/Validation');
const pageValidationHookMock = path.resolve(__dirname, 'collections/Pages/__mocks__/Validation');

// Auth Strategies are server-only. If we do not alias it, it pulls in the whole crypto- and stream-stuff used by jsonwebtoken
const auth0Strategy = path.resolve(__dirname, 'collections/Users/Auth0StrategyByEmail');
const auth0StrategyMock = path.resolve(
  __dirname,
  'collections/Users/__mocks__/Auth0StrategyByEmail',
);

export default buildConfig({
  telemetry: false,
  /*
   Do not bind payload to a specific base url
   Since v1.14: setting it to `undefined` explicitely breaks the URl of media assets,
   so we must not set it at all
  serverURL: undefined,
  */
  admin: {
    user: Users.slug,
    logoutRoute: payloadRoutes.logout,
    components: {
      // Login with SSO
      afterLogin: [LoginButton()],
      // Allow the users to switch tenants
      beforeNavLinks: [TenantSwitcher],
    },
    bundler: webpackBundler(),
    webpack: (config) => {
      config.devtool = 'source-map';
      config.resolve.alias = {
        ...config.resolve.alias,
        [auth0Strategy]: auth0StrategyMock,
        [tenantAccessControl]: tenantAccessControlMock,
        [pageValidationHook]: pageValidationHookMock,
      };
      return config;
    },
  },
  cors: '*',
  collections: [Users, Pages, Media, ReusableContent],
  db: mongooseAdapter({
    url: env.MONGODB.URI,
    ...(env.MONGODB.hasCredentials() && {
      connectOptions: {
        auth: {
          username: env.MONGODB.USER,
          password: env.MONGODB.PASS,
        },
      },
    }),
  }),
  editor: slateEditor({}),
  upload: {
    defCharset: 'utf8',
    defParamCharset: 'utf8',
  },
  plugins,
  typescript: {
    outputFile: path.resolve(__dirname, 'payload-types.ts'),
  },
  graphQL: {
    schemaOutputFile: path.resolve(__dirname, 'generated-schema.graphql'),
  },
  localization: {
    locales: ['en', 'de'],
    defaultLocale: 'en',
    fallback: true,
  },
  onInit: async (payload) => {
    if (env.IS_DEV) await seedPages(payload);
    await createTechUser(payload, { email: tuEnv.TECH_USER_EMAIL, apiKey: tuEnv.FRONTEND_API_KEY });
  },
});
