import { Block } from 'payload/types';

export type Type = {
  blockType: 'billingPortal';
  blockName?: string;
};

export const BillingPortal: Block = {
  slug: 'billingPortal',
  labels: {
    singular: 'MSI Billing Portal',
    plural: 'MSI Billing Portals',
  },
  fields: [],
};
