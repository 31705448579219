import { Block } from 'payload/types';

export type Type = {
  blockType: 'privateWallet';
  blockName?: string;
};

export const PrivateWallet: Block = {
  slug: 'privateWallet',
  labels: {
    singular: 'Private Wallet',
    plural: 'Private Wallets',
  },
  fields: [],
};
