import { Block } from 'payload/types';
import { MediaType } from '../../../collections/Media/Media';
import { RichTextContent } from '../RichText';
import { slateEditor } from '@payloadcms/richtext-slate';

export type Type = {
  blockType: 'image';
  blockName?: string;
  image: MediaType;
  caption?: RichTextContent;
};

export const Image: Block = {
  slug: 'image',
  labels: {
    singular: 'Image',
    plural: 'Images',
  },
  fields: [
    {
      name: 'image',
      label: 'Image',
      type: 'upload',
      relationTo: 'media',
      filterOptions: {
        mimeType: { contains: 'image' },
      },
      required: true,
    },
    {
      name: 'caption',
      label: 'Caption',
      type: 'richText',
      localized: true,
      editor: slateEditor({
        admin: {
          elements: ['link'],
        },
      }),
    },
  ],
};
