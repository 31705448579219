import React from 'react';
import ReactSelect from 'payload/dist/admin/components/elements/ReactSelect';
import { useAuth } from 'payload/dist/admin/components/utilities/Auth';
import NavGroup from 'payload/dist/admin/components/elements/NavGroup';
import { useLocalStorage } from 'usehooks-ts';

const toOption = (value: string) => ({ label: value, value: value });

const TenantSwitcher = (): JSX.Element => {
  const tokenGroups = (useAuth()?.user?.groups as { group: string }[]) || [];
  const groups = tokenGroups.map(({ group }) => group);
  if (groups.length < 1) {
    console.error('User does not have groups in their token.');
    return (
      <NavGroup label="Tenant">
        <span>Error: No tenants in token</span>
      </NavGroup>
    );
  }
  // eslint-disable-next-line prefer-const
  let [currentTenant, setCurrentTenant] = useLocalStorage('tenant', groups[0]);
  if (!groups.includes(currentTenant)) {
    console.warn(
      'Local storage contains a reference to a tenant which the current user does not have access to. Resetting.',
    );
    currentTenant = groups[0];
    setCurrentTenant(currentTenant);
  }
  return (
    <NavGroup label="Tenant">
      <ReactSelect
        options={groups.map(toOption)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultValue={toOption(currentTenant)}
        isMulti={false}
        isClearable={false}
        onChange={({ value }) => setCurrentTenant(value)}
      ></ReactSelect>
    </NavGroup>
  );
};

export default TenantSwitcher;
