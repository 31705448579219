import { Block } from 'payload/types';
import { MediaType } from '../../../collections/Media/Media';
import { MenuLink } from '../Links/MenuLink';
import { Link } from '../Links/Link';
import type { Type as MenuLinkType } from '../Links/MenuLink';
import type { Type as LinkType } from '../Links/Link';
import { RichTextContent } from '../RichText';

export type Type = {
  blockType: 'header';
  blockName?: string;
  links?: (MenuLinkType | LinkType)[];
  navbarLinksPosition: 'left' | 'right';
  homeLink?: string;
  brandIcon: 'none' | 'audi' | 'msi' | 'custom';
  image?: MediaType;
  theme?: 'light' | 'dark';
  text?: string;
  caption?: RichTextContent;
  isSticky: boolean;
};

export const Header: Block = {
  slug: 'header',
  labels: {
    singular: 'Header',
    plural: 'Headers',
  },
  fields: [
    {
      label: 'Header title',
      name: 'text',
      type: 'text',
      localized: true,
    },
    {
      label: 'Home link',
      name: 'homeLink',
      type: 'text',
    },
    {
      label: 'Brand icon',
      name: 'brandIcon',
      type: 'radio',
      options: [
        {
          label: 'None',
          value: 'none',
        },
        {
          label: 'Audi',
          value: 'audi',
        },
        {
          label: 'MSI',
          value: 'msi',
        },
        {
          label: 'Custom',
          value: 'custom',
        },
      ],
      defaultValue: 'noIcon',
      admin: {
        layout: 'horizontal',
      },
    },
    {
      label: 'Upload custom brand icon',
      name: 'image',
      type: 'upload',
      relationTo: 'media',
      filterOptions: {
        mimeType: { contains: 'image' },
      },
      admin: {
        condition: (_, siblingData) => siblingData?.brandIcon === 'custom',
      },
    },
    {
      label: 'Links position',
      name: 'navbarLinksPosition',
      type: 'radio',
      options: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ],
      defaultValue: 'left',
      admin: {
        layout: 'horizontal',
      },
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
      },
    },
    {
      label: 'Menu links',
      name: 'links',
      type: 'blocks',
      minRows: 0,
      blocks: [MenuLink, Link],
    },
    {
      label: 'Sticky',
      name: 'isSticky',
      type: 'checkbox',
      defaultValue: false,
      admin: {
        description: () => `Header will scroll together with the page.`,
      },
    },
  ],
};
