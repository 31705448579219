import type { Block } from 'payload/types';
import { ReusableContentType } from '../../../collections/ReusableContent/ReusableContent';

export type Type = {
  blockType: 'reusableContentBlock';
  blockName?: string;
  reusableContent: ReusableContentType;
};

export const ReusableContent: Block = {
  slug: 'reusableContentBlock',
  labels: {
    singular: 'Reusable content block',
    plural: 'Reusable content blocks',
  },
  fields: [
    {
      name: 'content',
      label: 'Content',
      type: 'relationship',
      relationTo: 'reusable-content',
      admin: {
        description:
          "Shared components from other tenants may be selectable, but not editable if you don't have access to the origin tenant.",
      },
      required: true,
      filterOptions: ({ data }) => {
        return {
          or: [{ tenant: { equals: data.tenant } }, { shared: { equals: true } }],
        };
      },
    },
  ],
};
