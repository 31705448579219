import { Block } from 'payload/types';

export type Type = {
  blockType: 'welcomeScreen';
  blockName?: string;
};

export const WelcomeScreen: Block = {
  slug: 'welcomeScreen',
  labels: {
    singular: 'Welcome Screen',
    plural: 'Welcome Screen',
  },
  fields: [],
};
