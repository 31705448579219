import { Block } from 'payload/types';

export type Type = {
  blockType: 'audiWallet';
  blockName?: string;
};

export const AudiWallet: Block = {
  slug: 'audiWallet',
  labels: {
    singular: 'Audi Wallet',
    plural: 'Audi Wallets',
  },
  fields: [],
};
