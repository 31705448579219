import { Block } from 'payload/types';
import { RichTextContent } from '../RichText';

export type Type = {
  blockType: 'accordion';
  blockName?: string;
  headline?: RichTextContent;
  sections: {
    blockType: 'accordionSection';
    blockName?: string;
    title: string;
    content: RichTextContent;
    id?: string;
  }[];
  theme: 'light' | 'dark';
};

const AccordionSection: Block = {
  slug: 'accordionSection',
  labels: {
    singular: 'Section',
    plural: 'Sections',
  },
  fields: [
    {
      name: 'title',
      label: 'Section Title',
      type: 'text',
      required: true,
      localized: true,
    },
    {
      name: 'content',
      label: 'Section Content',
      type: 'richText',
      required: true,
      localized: true,
    },
  ],
};

export const Accordion: Block = {
  slug: 'accordion',
  labels: {
    singular: 'Accordion',
    plural: 'Accordions',
  },
  fields: [
    {
      name: 'headline',
      label: 'Headline',
      type: 'richText',
      localized: true,
    },
    {
      name: 'sections',
      label: 'Sections',
      type: 'blocks',
      minRows: 1,
      blocks: [AccordionSection],
      required: true,
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
        description:
          'Theme of the image (Light provides grey text color, Dark provides white color).',
      },
    },
  ],
};
