import { useField } from 'payload/components/forms';
import React from 'react';
import TextInput from 'payload/dist/admin/components/forms/field-types/Text/Input';
import { useReadLocalStorage } from 'usehooks-ts';

type Props = { label: string; path: string; required: boolean };

const TenantField: React.FC<Props> = ({ label, path, required }) => {
  const { value, setValue, initialValue } = useField<string>({ path });
  const tenant = useReadLocalStorage<string>('tenant');

  React.useEffect(() => {
    // Tenant can only be set (using the tenant switch) when creating a new entity
    if (!initialValue && value !== tenant) {
      // A falsy initial value indicates that we are creating a new item
      setValue(tenant);
    }
  }, [initialValue, value, tenant]);

  return (
    <TextInput
      name="Tenant"
      label={label}
      path={path}
      readOnly={true}
      value={value}
      required={required}
    />
  );
};

export default TenantField;
