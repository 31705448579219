import { Payload } from 'payload';
import * as crypto from 'crypto';
import * as env from '../techuser.environment';

/**
 * Create a new user with a random password, so it cannot log in.
 * Provide the API key to allow programmatic access.
 * @param payload Do operations on the payload instance.
 * @param credentials: Email and API key of the tech user
 */
export const createTechUser = async (
  payload: Payload,
  credentials: { email: string; apiKey: string },
): Promise<void> => {
  const tuQuery = await payload.find({
    collection: 'users',
    where: {
      email: {
        equals: env.TECH_USER_EMAIL,
      },
    },
  });

  if (tuQuery.docs.length === 0) {
    await payload.create({
      collection: 'users',
      data: {
        ...credentials,
        sub: 'techuser|noIDP',
        // The tech user is not allowed to log in, and doesn't have a connection to the IDP anyway
        password: crypto.randomBytes(20).toString('hex'),
        // We just create the user for the API key
        enableAPIKey: true,
      },
    });

    payload.logger.info('Tech user created successfully!');
  }
};
