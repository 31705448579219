import { Block } from 'payload/types';

export type Type = {
  blockType: 'userProfile';
  blockName?: string;
};

export const UserProfile: Block = {
  slug: 'userProfile',
  labels: {
    singular: 'User Profile',
    plural: 'User Profiles',
  },
  fields: [],
};
