import { RichTextContent } from '../RichText';
import {
  Type as EditorialTeaserComponentType,
  EditorialTeaserComponent,
} from './EditorialTeaserComponent';
import { Block } from 'payload/types';

export type Type = {
  blockType: 'editorialTeaser';
  blockName?: string;
  components?: EditorialTeaserComponentType[];
  theme: 'light' | 'dark';
  disclaimer?: RichTextContent;
};

export const EditorialTeaser: Block = {
  slug: 'editorialTeaser',
  labels: {
    singular: 'Editorial Teaser',
    plural: 'Editorial Teasers',
  },
  fields: [
    {
      label: 'Components',
      name: 'components',
      type: 'blocks',
      minRows: 1,
      maxRows: 3,
      blocks: [EditorialTeaserComponent],
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
        description:
          'Theme of the image (Light provides grey text color, Dark provides white color).',
      },
    },
    {
      label: 'Disclaimer',
      name: 'disclaimer',
      type: 'richText',
      localized: true,
    },
  ],
};
