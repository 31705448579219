import type { CollectionConfig } from 'payload/types';
import { TenantAccessControl } from '../../admin/TenantAccessControl';

import { blocks as commonBlocks } from '../../blocks/common';
import { blocks as msiBlocks } from '../../blocks/msi';
import { blocks as nftBlocks } from '../../blocks/nft';
import TenantField from '../../admin/TenantField';
import TenantFilteringList from '../../admin/TenantFilteringList';
import { Layout } from '../Pages/Pages';

export type ReusableContentType = {
  id: string | number;
  title: string;
  tenant: string;
  layout: Layout[];
  shared: boolean;
};

const ReusableContent: CollectionConfig = {
  slug: 'reusable-content',
  admin: {
    useAsTitle: 'title',
    components: {
      views: {
        List: TenantFilteringList,
      },
    },
  },
  access: new TenantAccessControl(),
  labels: {
    singular: 'Reusable Content',
    plural: 'Reusable Contents',
  },
  fields: [
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      required: true,
    },
    {
      name: 'shared',
      label: 'Globally shared',
      admin: {
        description: 'If checked, this reusable content will be accessible by all tenants.',
      },
      type: 'checkbox',
      required: true,
      defaultValue: false,
    },
    {
      name: 'layout',
      label: 'Layout',
      type: 'blocks',
      required: true,
      blocks: [...commonBlocks, ...msiBlocks, ...nftBlocks],
    },
    {
      name: 'tenant',
      label: 'Tenant',
      type: 'text',
      required: true,
      admin: {
        position: 'sidebar',
        components: {
          Field: TenantField,
        },
      },
    },
  ],
};

export default ReusableContent;
