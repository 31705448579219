import React from 'react';
import DefaultList from 'payload/dist/admin/components/views/collections/List/Default';
import { Props as ListProps } from 'payload/dist/admin/components/views/collections/List/types';
import { useReadLocalStorage } from 'usehooks-ts';

/**
 * Filters the data in a list by the tenant that is currently selected.
 * @param props This is passed through to payload's DefaultList. data.docs is filtered by tenant
 */
const TenantFilteringList: React.FC<ListProps> = (props: ListProps) => {
  const tenant = useReadLocalStorage<string>('tenant');
  const filteredData = props.data && {
    ...props.data,
    docs: props.data?.docs?.filter((doc) => !doc.tenant || doc.tenant === tenant),
  };
  return <DefaultList {...props} data={filteredData} />;
};

export default TenantFilteringList;
