import { Payload } from 'payload';
import path from 'path';

export const seedPages = async (payload: Payload): Promise<void> => {
  // On init, we'll check to see if there is a demo page
  const demoQuery = await payload.find({
    collection: 'pages',
    where: {
      slug: {
        equals: 'demo',
      },
    },
  });

  // If there isn't, then seed a demo page
  if (demoQuery.docs.length === 0) {
    const audiEtronImage = await payload.create({
      collection: 'media',
      data: {
        alt: 'e-tron',
        tenant: 'papyrus-administrators',
      },
      filePath: path.resolve(__dirname, './e-tron.jpeg'),
    });

    await payload.create({
      collection: 'pages',
      data: {
        title: 'Demo',
        slug: 'demo',
        tenant: 'papyrus-administrators',
        layout: [
          {
            image: audiEtronImage.id,
            heading: [{ children: [{ text: 'e-tron Prototype' }] }],
            subHeading: [{ children: [{ text: 'Audi performance, electrified.' }] }],
            text: [
              {
                children: [
                  {
                    text: 'The first fully electric sportscars from Audi are designed to perform.',
                  },
                ],
              },
            ],
            mediaPosition: 'right',
            theme: 'dark',
            blockType: 'innovationTeaser',
          },
        ],
      },
    });

    payload.logger.info('Demo page seeded successfully!');
  }
};
