import { MediaType } from '../../../../collections/Media/Media';
import { RichTextContent } from '../../RichText';
import { Type as LinkType, Link } from '../../Links/Link';
import { Block } from 'payload/types';

export type Type = {
  blockType: 'editorialTeaserComponent';
  blockName?: string;
  image?: MediaType;
  heading?: RichTextContent;
  text?: RichTextContent;
  buttonLink?: LinkType[];
};

export const EditorialTeaserComponent: Block = {
  slug: 'editorialTeaserComponent',
  labels: {
    singular: 'Editorial Teaser Component',
    plural: 'Editorial Teaser Components',
  },
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      type: 'richText',
      localized: true,
    },
    {
      label: 'Text',
      name: 'text',
      type: 'richText',
      localized: true,
    },
    {
      label: 'Image',
      name: 'image',
      type: 'upload',
      relationTo: 'media',
      required: true,
      filterOptions: {
        mimeType: { contains: 'image' },
      },
    },
    {
      label: 'Button link',
      name: 'buttonLink',
      type: 'blocks',
      minRows: 0,
      maxRows: 1,
      blocks: [Link],
    },
  ],
};
