import { AudiWallet, Type as AudiWalletType } from './AudiWallet';
import { PrivateWallet, Type as PrivateWalletType } from './PrivateWallet';
import { UserProfile, Type as UserProfileType } from './UserProfile';
import { WelcomeScreen, Type as WelcomeScreenType } from './welcomeScreen';
import { ChooseLogin, Type as ChooseLoginType } from './chooseLogin';
import { LoginWithAudiId, Type as LoginWithAudiIdType } from './loginWithAudiId';

export type LayoutType =
  | UserProfileType
  | PrivateWalletType
  | AudiWalletType
  | WelcomeScreenType
  | ChooseLoginType
  | LoginWithAudiIdType;

export const blocks = [
  UserProfile,
  PrivateWallet,
  AudiWallet,
  WelcomeScreen,
  ChooseLogin,
  LoginWithAudiId,
];
