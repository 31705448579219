import { Block } from 'payload/types';

export type RichTextContent = {
  [k: string]: unknown;
}[];

export type Type = {
  blockType: 'richText';
  blockName?: string;
  content: RichTextContent;
  fullWidth: boolean;
  textAlignment: 'left' | 'center' | 'right';
  horizontalContentAlignment: 'left' | 'center' | 'right';
  theme: 'light' | 'dark';
};

export const RichText: Block = {
  slug: 'richText',
  labels: {
    singular: 'Rich Text',
    plural: 'Rich Texts',
  },
  fields: [
    {
      name: 'content',
      type: 'richText',
      label: 'Content',
      localized: true,
    },
    {
      name: 'fullWidth',
      type: 'checkbox',
      label: 'Full width',
      defaultValue: false,
    },
    {
      name: 'horizontalContentAlignment',
      label: 'Content Alignment (Horizontal)',
      type: 'radio',
      options: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Center',
          value: 'center',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ],
      defaultValue: 'left',
      admin: {
        layout: 'horizontal',
        description: 'Alignment of the whole Textbox',
      },
    },
    {
      name: 'textAlignment',
      label: 'Text Alignment',
      type: 'radio',
      options: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Center',
          value: 'center',
        },
        {
          label: 'Right',
          value: 'right',
        },
      ],
      defaultValue: 'left',
      admin: {
        layout: 'horizontal',
        description: 'Alignment of the Text content inside the Textbox',
      },
    },
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
      },
    },
  ],
};
