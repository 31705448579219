import { Block } from 'payload/types';
import { Link } from './Link';
import type { Type as LinkType } from './Link';

export type Type = {
  blockType: 'navigationLink';
  blockName?: string;
  text?: string[];
  subLinks?: LinkType[];
};

export const MenuLink: Block = {
  slug: 'navigationLink',
  labels: {
    singular: 'Link group',
    plural: 'Link groups',
  },
  fields: [
    {
      label: 'Link title',
      name: 'text',
      type: 'text',
      localized: true,
      required: true,
    },
    {
      label: 'Submenu links',
      name: 'subLinks',
      type: 'blocks',
      minRows: 1,
      blocks: [Link],
      admin: {
        description: 'Additional links that appear in Drawer/Flyout after the Link is clicked.',
      },
    },
  ],
};
