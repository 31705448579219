import { Block } from 'payload/types';

export type Type = {
  text: string;
  href: string;
  newTab: boolean;
};

export const Link: Block = {
  slug: 'Link',
  labels: {
    singular: 'Link',
    plural: 'Links',
  },
  fields: [
    {
      label: 'Link title',
      name: 'text',
      type: 'text',
      localized: true,
      required: true,
    },
    {
      label: 'Target URL',
      name: 'href',
      type: 'text',
      localized: true,
      required: true,
    },
    {
      label: 'Open in new Tab',
      name: 'newTab',
      type: 'checkbox',
      required: true,
      defaultValue: false,
    },
  ],
};
