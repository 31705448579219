import { Access, CollectionConfig } from 'payload/types';
import { payloadAdminGroup } from '../../auth.environment';
import { Auth0StrategyByEmail } from './Auth0StrategyByEmail';

export type UserType = {
  id: string;
  sub: string;
  groups: {
    group: string;
    id: string;
  }[];
  email: string;
};

/**
 * Only allow admins of papyrus to manage all users.
 * @param user Active user that wants to access the data
 */
const adminAccess: Access<never, UserType> = ({ req: { user } }) => {
  return user?.groups?.some(({ group }) => group === payloadAdminGroup);
};

/**
 * Allow each user to access their own data (required to log in).
 * Additionally, allow the admin to access the data.
 * @param id ID of the data that should be accessed
 * @param user Active user that wants to access the data
 */
const selfAndAdminAccess: Access<never, UserType> = ({ id, req }) => {
  return (id && req.user?.id === id) || adminAccess({ id, req });
};

const Users: CollectionConfig = {
  slug: 'users',
  auth: {
    strategies: [
      {
        name: Auth0StrategyByEmail.name,
        strategy: (ctx) => {
          return new Auth0StrategyByEmail(ctx, Users.slug);
        },
      },
    ],
    useAPIKey: true,
  },
  access: {
    read: selfAndAdminAccess,
    create: selfAndAdminAccess,
    update: selfAndAdminAccess,
    delete: adminAccess,
    unlock: adminAccess,
  },
  fields: [
    // Email added by default
    {
      name: 'sub',
      type: 'text',
      admin: {
        readOnly: true,
        condition: (data) => {
          return data.sub;
        },
      },
    },
    {
      name: 'groups',
      type: 'array',
      fields: [{ name: 'group', type: 'text' }],
      admin: {
        readOnly: true,
        condition: (data) => {
          return data.groups;
        },
      },
    },
  ],
  hooks: {
    beforeValidate: [
      /**
       * Properties with dots in their names cannot be saved in the DB,
       * so they are extracted and stored in a sane field.
       * @param data Raw data coming from the claims of the token. API says this could be
       * `undefined`, so a default is provided.
       */
      ({ data = {} }) => {
        const groups = data['https://msi.audi.com/groups'] as string[];
        if (groups) {
          data.groups = groups.map((group) => ({
            group,
          }));
          delete data['https://msi.audi.com/groups'];
        }
        return data;
      },
    ],
  },
  admin: {
    useAsTitle: 'email',
  },
};

export default Users;
