import { Block } from 'payload/types';

export type Type = {
  blockType: 'loginWithAudiId';
  blockName?: string;
};

export const LoginWithAudiId: Block = {
  slug: 'loginWithAudiId',
  labels: {
    singular: 'Login With Audi Id',
    plural: 'Login With Audi Id',
  },
  fields: [],
};
