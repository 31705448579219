/* eslint-disable node/no-process-env */
// We cannot use env-var here, because this config is required for build-time
import dotenv from 'dotenv';

dotenv.config();

export const NODE_ENV = process.env.NODE_ENV;
export const IS_PROD = NODE_ENV === 'production';
export const IS_DEV = NODE_ENV === 'development';

// Allow to disable the S3 cloud storage adapter for local testing. Attention: Any string will do it!
export const DISABLE_S3_CLOUD_STORAGE_PLUGIN = process.env.DISABLE_S3_CLOUD_STORAGE_PLUGIN;

// Variables below are required at RUN-TIME

// Access mongoDB
export const MONGODB = {
  /**
   * Connection string to MongoDB or DocumentDB,
   * e.g. 'mongodb://mongo.example:27017/?retryWrites=false'
   */
  URI: process.env.MONGODB_URI,
  USER: process.env.MONGODB_USER,
  PASS: process.env.MONGODB_PASS,
  hasCredentials: (): boolean => Boolean(MONGODB.USER && MONGODB.PASS),
};

// Required to store assets on S3
export const ASSETS_BUCKET_NAME = process.env.ASSETS_BUCKET_NAME;
// Provide access to assets via CDN
export const CDN_DOMAIN = process.env.CDN_DOMAIN;
