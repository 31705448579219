import { Block } from 'payload/types';
import { Link, Type as LinkType } from '../Links/Link';
import { MenuLink, Type as MenuLinkType } from '../Links/MenuLink';

export type Type = {
  blockType: 'footer';
  blockName?: string;
  links?: (MenuLinkType | LinkType)[];
  bottomLinks?: LinkType[];
  theme?: 'light' | 'dark';
  languageChooser: boolean;
  copyright?: string;
};

export const Footer: Block = {
  slug: 'footer',
  labels: {
    singular: 'Footer',
    plural: 'Footers',
  },
  fields: [
    {
      name: 'theme',
      label: 'Theme',
      type: 'radio',
      options: [
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ],
      defaultValue: 'light',
      admin: {
        layout: 'horizontal',
      },
    },
    {
      name: 'languageChooser',
      label: 'Language chooser',
      type: 'checkbox',
      defaultValue: false,
    },
    {
      label: 'Copyright notice',
      name: 'copyright',
      type: 'text',
      localized: true,
      admin: {
        description: '"©" and year prepended automatically.',
      },
    },
    {
      label: 'Link groups',
      name: 'links',
      type: 'blocks',
      minRows: 0,
      blocks: [MenuLink, Link],
    },
    {
      label: 'Bottom links',
      name: 'bottomLinks',
      type: 'blocks',
      minRows: 0,
      blocks: [Link],
    },
  ],
};
