import { Block } from 'payload/types';

export type Type = {
  blockType: 'chooseLogin';
  blockName?: string;
};

export const ChooseLogin: Block = {
  slug: 'chooseLogin',
  labels: {
    singular: 'Choose Login',
    plural: 'Choose Login',
  },
  fields: [],
};
