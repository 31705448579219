import { BeforeDuplicate } from 'payload/types';
import { PageType } from './Pages';
import { getSlugPerTenantName } from './Helper';

export const handleDuplicate: BeforeDuplicate<PageType> = ({ data }) => {
  let slug = data.slug;

  if (!slug) {
    slug = 'home';
  }

  slug = `${slug}-copy`;
  const slugPerTenant = getSlugPerTenantName(data.tenant, slug);

  return {
    ...data,
    slug,
    slugPerTenant,
    title: `${data.title} Copy`,
  };
};
